import { useEffect, useState } from 'react';
import './App.css';
import GameCard from './components/game-card/GameCard.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { showToast } from './utils/showToast.js';
import { closeLoading, showLoading } from './components/ShowLoading.js';


function App() {
  const [games, setGames] = useState([]);
  const [telegramId, setTelegramId] = useState(null);
  const [betamount, setBetAmount] = useState(null);
  const [balance, setBalance] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const telegram_id = queryParams.get('telegram_id');
    const bet_amount = queryParams.get('amount');
    setTelegramId(telegram_id);
    setBetAmount(bet_amount);
    async function fetchGames() {
      let results;
      try{
        showLoading();
        results = await Promise.all([
          axios.get(`${process.env.REACT_APP_URL}/api/games/${bet_amount}`),
          axios.post(`${process.env.REACT_APP_URL}/api/balance`, {
            telegram_id:telegram_id,
          })
        ]);
      }catch(error){
        showToast('Oops...', 'Something went wrong', 'error');
      }finally{
        closeLoading();
      }
      if (results[0].status === 200) {
        setGames(results[0].data);
      }
      if (results[1].status === 200) {
        const {balance} = results[1].data;
        setBalance(balance);
      }
    }
    fetchGames();
  }, []);
  
  useEffect(() => {
      if(telegramId!==null && betamount!==null && balance!==null && games.length===1){
          navigate(`/select`, {state:{balance:balance, game:games[0], telegram_id:telegramId}});
      }
  }, [telegramId, betamount, balance]);
  
  

  const onGameClick = async (game) => {
    navigate(`/select`, {state:{balance:balance, game:game, telegram_id:telegramId}});
  };
  return (
    <div>
      <h3 className='initail-title'>Choose a game that has free cartela</h3>
      {games.map(game => (
        <div key={game.id} onClick={(e)=>onGameClick(game)}>
          <GameCard game={game} />
        </div>
      ))}
    </div>
  );
}

export default App;
