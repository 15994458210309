import React from 'react'

export default function Cartela({card, onClick, clickedNumbers}) {
    const handleCardClick = (num)=>{
        onClick(num)
    };
    return (
        <div className="my-1 !grid !grid-cols-5 gap-1 item-size-xs bg-orange-950 rounded">
        {Object.keys(card.cartela).map((letter) => (
            <div className={
                letter==="B"?'text-center item-size-s text-blue-500 bg-lime-300 rounded'
                :letter==="I"?'text-center item-size-s text-blue-500 bg-green-500 rounded'
                :letter==="N"?'text-center item-size-s text-blue-500 bg-orange-500 rounded'
                :letter==="G"?'text-center item-size-s text-red-950 bg-indigo-500 rounded'
                :letter==="O"?'text-center item-size-s text-blue-500 bg-yellow-200 rounded'
                :'text-center item-size-s text-blue-500 bg-yellow-500 rounded'
                } 
                key={letter}>
                {letter}
                </div>
        ))}
          {Object.values(card.cartela).map((column, colIdx) => (
        <div key={colIdx}>
          {column.map((num, rowIdx) => (
            <div key={rowIdx} onClick={()=>handleCardClick(num)} className={(clickedNumbers.includes(num) || num==='Free')? "my-1 py-1 text-center text-white font-bold justify-center rounded bg-blue-950":" my-1 py-1 text-center text-stone font-bold justify-center rounded bg-green-200"}>
              {num==='Free'? '*':num}
            </div>
          ))}
        </div>
      ))}
      <div className='text-white text-nowrap ml-5'>Cartela # {card.display_id}</div>
    </div>
      );
}
