// src/components/GameCard.js
import React, { useEffect, useState } from 'react';
import '../css/GameCard.css';
import useEcho from '../../hooks/echo';
import Timer from './Timer';

export default function GameCard({ game }) {
  const echo = useEcho();

  const [gameValue, setGameValue] = useState(game);

  useEffect(() => {
    if (echo) {
      echo.channel(`update-game.${game.id}`)
        .listen('GameUpdated', (e) => {
          setGameValue(e.game);
        });
    }
    return () => {
      if (echo) {
        echo.channel(`update-game.${game.id}`).stopListening('GameUpdated');
      }
    }
  }, [echo, game.id]);

  return (
    <div className='game-card'>
      <div className='game-card-header'>
        <div className='game-card-title'>Game {game.id}</div>
        <Timer game={gameValue} />
      </div>
      <p>Players: {gameValue.players_count}/100</p>
      <p>Bet Amount: {gameValue.bet_amount}</p>
    </div>
  );
};

