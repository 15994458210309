import React from 'react';

function BingoBoard({ prevCalls = [], currentCall }) {
    const numbers = Array.from({ length: 75 }, (_, i) => i + 1);
    const letters = ["B", "I", "N", "G", "O"];
    // Split numbers into chunks of 15
    const chunks = [];
    for (let i = 0; i < numbers.length; i += 15) {
        chunks.push(numbers.slice(i, i + 15));
    }

    return (
        <div className="grid !grid-cols-5 gap-1 item-size-xs">
            {letters.map((letter, index) => (
                <div key={letter}>
                    <div className={
                        letter === "B" ? 'text-center item-size-s text-blue-500 bg-lime-300 rounded'
                            : letter === "I" ? 'text-center item-size-s text-blue-500 bg-green-500 rounded'
                                : letter === "N" ? 'text-center item-size-s text-blue-500 bg-orange-500 rounded'
                                    : letter === "G" ? 'text-center item-size-s text-red-950 bg-indigo-500 rounded'
                                        : letter === "O" ? 'text-center item-size-s text-blue-500 bg-yellow-200 rounded'
                                            : 'text-center item-size-s text-blue-500 bg-yellow-500 rounded'
                    }>
                        {letter}
                    </div>
                    {chunks[index].map((number) => (
                        <div
                            key={number}
                            className={(prevCalls.includes(number)&& currentCall!==number) ? "bg-yellow-950 text-white text-center px-0 mx-0 py-1 rounded-md text-sm" :(number===currentCall)? "bg-black text-center text-white px-0 mx-0 py-1 rounded-md text-sm animate-blink":"bg-blue-200 text-center px-0 mx-0 py-1 rounded-md text-sm"}
                        >
                            {number}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default BingoBoard;
