// BingoCard.js
import React from 'react';

export default function BingoCard ({ card, prevCalls=[], winningPatternBalls=[] }) {
  return (
    <div className="my-2 !grid !grid-cols-5 gap-1 item-size-xs bg-orange-950">
    {Object.keys(card).map((letter) => (
        <div className={
            letter==="B"?'text-center item-size-s text-blue-500 bg-lime-300 rounded'
            :letter==="I"?'text-center item-size-s text-blue-500 bg-green-500 rounded'
            :letter==="N"?'text-center item-size-s text-blue-500 bg-orange-500 rounded'
            :letter==="G"?'text-center item-size-s text-red-950 bg-indigo-500 rounded'
            :letter==="O"?'text-center item-size-s text-blue-500 bg-yellow-200 rounded'
            :'text-center item-size-s text-blue-500 bg-yellow-500 rounded'
            } 
            key={letter}>
            {letter}
            </div>
    ))}
      {Object.values(card).map((column, colIdx) => (
    <div key={colIdx}>
      {column.map((num, rowIdx) => (
        <div key={rowIdx} className={((prevCalls.includes(parseInt(num)) && winningPatternBalls.includes(parseInt(num))) || num==='Free')? "px-2 text-center text-white font-bold justify-center rounded-md bg-blue-950":(prevCalls.includes(parseInt(num)) && !winningPatternBalls.includes(parseInt(num)))? "px-2 text-center font-bold justify-center rounded-md bg-lime-300":"px-2 text-center text-stone font-bold justify-center rounded-md bg-green-200"}>
          {num==='Free'? '*':num}
        </div>
      ))}
    </div>
  ))}
</div>
  );
};