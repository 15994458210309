// src/utils/showToast.js
import Swal from 'sweetalert2';

export const showToast = (title, text, icon) => {
  Swal.fire({
    title: title,
    text: text,
    icon: icon,
    timer: 2000, // Auto close after 2 seconds
    showConfirmButton: false,
    timerProgressBar: false,
    // willClose: () => {
    //   clearInterval(timerInterval)
    // },
    // didOpen: () => {
    //   const content = Swal.getHtmlContainer();
    //   const $ = content.querySelector.bind(content);
    //   timerInterval = setInterval(() => {
    //     const b = Swal.getTimerLeft()
    //     if (b) {
    //       $('.swal2-timer-progress-bar').style.width = `${b / 30}%`;
    //     }
    //   }, 100)
    // }
  });
};
