import React, { useEffect, useState } from 'react';
import '../components/css/CardGrid.css';
import { useLocation, useNavigate } from 'react-router-dom';
import BingoCard from '../components/BingoCard';
import useEcho from '../hooks/echo';
import axios from 'axios';
import { showToast } from '../utils/showToast';
import { closeLoading, showLoading } from '../components/ShowLoading';


export default function CardGrid() {
    const location = useLocation();
    const [selectedCard, setSelectedCard] = useState(null);
    const [balance, setBalance] = useState(null);
    const [game, setGame] = useState({});
    const [cartelaList, setCartelaList] = useState([]);
    const [telegramId, setTelegramId] = useState(null);
    let echo = useEcho();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state !== undefined) {
            setBalance(location.state.balance);
            console.log(location.state.balance);
            setGame(location.state.game);
            setTelegramId(location.state.telegram_id);
            async function getGemeData() {
                try {
                  const response = await axios.get(`${process.env.REACT_APP_URL}/api/game/single/${location.state.game.id}`);
                  if (response.status === 200) {
                    const { game } = response.data;
                    setGame(game);
                  }
                } catch {
                  showToast('', 'Something went wrong', 'error');
                }
              }
              getGemeData();
        }
    }, [location.state]);

    useEffect(() => {
        if (echo) {
            echo.channel(`update-game.${game.id}`)
                .listen('GameUpdated', (e) => {
                    setGame((prevGame) => {
                        const newCartelas = prevGame.cartelas.map(cartela => cartela.id === e.cartela.id ? e.cartela : cartela);
                        const newGame = {
                            ...prevGame,
                            cartelas: newCartelas,
                            players_count: e.game.players_count,
                            status: e.game.status,
                            time_left: e.game.time_left
                        };
                        return newGame;
                    });
                });
        }
        return () => {
            if (echo) {
                echo.channel(`update-game.${game.id}`).stopListening('GameUpdated');
            }
        };
    }, [echo, game.id]);

    useEffect(() => {
        if (game.cartelas !== undefined) {
            setCartelaList(game.cartelas);
        }
    }, [game]);

    const handleCardClick = (card) => {
        setSelectedCard(card);
    };

    const onReturnClick = () => {
        window.location.href = '/';
    };

    const onJoinGame = async () => {
        if (balance!==null && (parseFloat(balance) <= parseFloat(game.bet_amount))) {
            showToast('Low Balance!', 'Please topup your wallet to play!', 'warning');
            return;
        }
        if (game.status === 'active') {
            showToast('Game running', 'Please wait untill the current running game ends', 'info');
            return;
        }
        try {
            showLoading();
            const response = await axios.post(`${process.env.REACT_APP_URL}/api/place-bet/${game.id}`, {
                telegram_id: telegramId,
                bet_amount: game.bet_amount,
                selected_cartela: selectedCard.id
            });
            if (response.status === 200) {
                closeLoading();
                const { selectedCartela, balanceR } = response.data;
                navigate('/game', {state:{game:game, cartela:selectedCartela, balance:balanceR, telegram_id:telegramId}});
            }
        } catch (error) {
            closeLoading();
            if (error.response && error.response.data) {
                showToast(error.response.data.error, '', 'error');
            } else {
                showToast('Oops...', 'Something went wrong!', 'error');
            }
        }
    };

    return (
        <div className="w-11/12 m-auto flex flex-col items-center">
            <div className="info-cards">
                <div className="mx-1 px-1 bg-white text-center text-blue-300 rounded">
                    <div className="info-title text-nowrap">Wallet</div>
                    <div className="text-lg">{balance}</div>
                </div>
                <div className="mx-1 px-1 bg-white text-center text-blue-300 rounded">
                    <div className="info-title text-nowrap">Status</div>
                    <div className="text-lg">{game.status === 'active' ? 'Running' : 'Waiting'}</div>
                </div>
                <div className="mx-1 px-1 bg-white text-center text-blue-300 rounded">
                    <div className="info-title text-nowrap">Bet</div>
                    <div className="text-lg">{game.bet_amount}</div>
                </div>
            </div>
            <div className="grid !grid-cols-10 gap-1">
                {cartelaList.map((card) => (
                    <div
                        key={card.id}
                        className={(selectedCard && selectedCard.id === card.id && card.user_id === null) ? "card  selected" : ((card.user_id === null) ? "card" : "card disabled")}
                        onClick={() => handleCardClick(card)}
                    >
                        {card.display_id}
                    </div>
                ))}
            </div>
                <div className='flex justify-center text-center max-w-full'>
                {selectedCard && (<BingoCard card={selectedCard.cartela} />)}
                    <div className="my-5">
                        <button onClick={onJoinGame} className="responsive-button mt-3 mb-1">Join Game</button>
                        <button onClick={onReturnClick} className="responsive-button my-1">Return</button>
                    </div>
                </div>
        </div>
    );
};