import React, { useEffect, useState } from 'react'

export default function Timer({ game }) {
    const [timeLeft, setTimeLeft] = useState(game.time_left);

    useEffect(() => {
        if (timeLeft <= 0) {
            return;
        }
        const timeout = setTimeout(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);
        return () => clearTimeout(timeout);
    }, [timeLeft]);
    if(timeLeft===0){
        return (<div>Playing...</div>);
    }
    return (
        <div className='game-card-timer'><div>{timeLeft}</div></div>
    )
}
