import React, { useEffect, useReducer } from 'react'
import BingoBoard from '../components/BingoBoard'
import '../components/css/CardGrid.css';
import Cartela from '../components/Cartela';
import { useLocation, useNavigate } from 'react-router-dom';
import useEcho from '../hooks/echo';
import axios from 'axios';
import { showToast } from '../utils/showToast';
import Swal from 'sweetalert2';
import BingoCard from '../components/BingoCard';
import withReactContent from 'sweetalert2-react-content';
import { closeLoading, showLoading } from '../components/ShowLoading';



export default function GamePage() {
  const initialState = {
    historyBalls: [],
    currentBall: null,
    prevCalls: [],
    clickedNumbers: [],
    cartela: null,
    winnerCartela: null,
    winningPatternBalls: [],
    winnerName: null,
    game: null,
    totalCall: 0,
    playerNumber: null,
    timeLeft: 0,
    winningPattern: false,
    giveout: null,
    balance: null,
    telegramId: null,
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case 'GAME_START':
        return {
          ...state,
          playerNumber: action.payload.players_count,
          giveout: Math.floor(action.payload.players_count * action.payload.bet_amount * 0.8),
        };
      case 'BALL_DRAW':
        const last5Calls = [...state.historyBalls];
        if (last5Calls.length >= 5) {
          last5Calls.shift();
        }
        if(state.currentBall!==null){
          last5Calls.push(state.currentBall);
        }
        const prevCalls = [...state.prevCalls, action.payload.ball];
        return {
          ...state,
          historyBalls: last5Calls,
          prevCalls: prevCalls,
          currentBall: action.payload.ball,
          totalCall: state.totalCall + 1,
        };
      case 'END_GAME':
        return {
          ...state,
          winnerCartela: action.payload.cartela,
          winningPatternBalls: action.payload.winningPatternBalls,
          winnerName: action.payload.name,
          winningPattern: true,
        };
      case 'SET_STATE':
        return {
          ...state,
          ...action.payload,
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const setNewState = (newState) => {
    dispatch({
      type: 'SET_STATE',
      payload: newState,
    });
  };
  const location = useLocation();
  const echo = useEcho();
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state !== undefined) {
      setNewState({ game: location.state.game, cartela: location.state.cartela, balance: location.state.balance, telegramId: location.state.telegram_id });
      async function getGemeData() {
        try {
          showLoading();
          const response = await axios.get(`${process.env.REACT_APP_URL}/api/game/detail/${location.state.game.id}`);
          if (response.status === 200) {
            const { timeLeft, game } = response.data;
            setNewState({ timeLeft: timeLeft, game: game });
          }
        } catch {
          showToast('', 'Something went wrong', 'error');
        }finally{
          closeLoading();
        }
      }
      getGemeData();
    }
  }, [location.state]);

  useEffect(() => {
    if (echo) {
      const channel = echo.channel('game.' + state.game.id);
      
      const handleGameStarted = (e) => {
        dispatch({
          type: 'GAME_START',
          payload: {
            players_count: e.game.players_count,
            bet_amount: e.game.bet_amount,
          },
        });
      };
  
      const handleBallDrawn = (e) => {
        dispatch({
          type: 'BALL_DRAW',
          payload: {
            ball: e.ball,
          },
        });
      };
  
      const handleGameEnded = (e) => {
        dispatch({
          type: 'END_GAME',
          payload: {
            cartela: e.cartela,
            winningPatternBalls: e.winningPatternBalls,
            name: e.name,
          },
        });
      };
  
      channel.listen('GameStart', handleGameStarted)
             .listen('BallDraw', handleBallDrawn)
             .listen('EndGame', handleGameEnded);
      
      return () => {
        if(echo){
          channel.stopListening('GameStart')
                 .stopListening('BallDraw')
                 .stopListening('EndGame');
        }
      };
    }
  }, [echo]);

  useEffect(() => {
    if (state.winningPattern && state.winnerCartela !== null) {
      MySwal.fire({
        title: <BingoCard card={state.winnerCartela.cartela} prevCalls={state.prevCalls} winningPatternBalls={state.winningPatternBalls} />,
        html: <div><strong>{state.winnerCartela.user_id===state.cartela.user_id? "You have":state.winnerName} </strong> won the game</div>,
        showConfirmButton: true, // Optional: To show the OK button
        didClose:()=>{
          navigate(`/select`, {state:{balance:state.balance, game:state.game, telegram_id:state.telegramId}});
        }
      });
    }
    return ()=>{
      setNewState({
        game: null,
        winnerCartela:null,
        cartela: null,
        historyBalls: [],
        prevCalls: [],
        clickedNumbers: [],
        totalCall: 0,
        winningPattern: false,
      });
    }
  }, [state.winningPattern, state.winnerCartela]);


  useEffect(() => {
    let timeout;
    if (state.timeLeft > 0) {
      timeout = setTimeout(() => {
        setNewState({ timeLeft: state.timeLeft - 1 });
      }, 1000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [state.timeLeft]);



  const bingo = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/declare/winner/${state.game.id}`, {
        clickedNumbers: state.clickedNumbers,
        cartela: state.cartela.id,
      });
      if(response.status===200){
        const {balance, game} = response.data;
        setNewState({ balance: balance, game: game });
      }
    } catch (error) {
      if(error.response && error.response.status===401){
        showToast(error.response.data.error, '', 'error');
        navigate(`/select`, {state:{balance:state.balance, game:state.game, telegram_id:state.telegramId}});
      } else if (error.response && error.response.data) {
        showToast(error.response.data.error, '', 'error');
      } else {
        showToast('Oops...', 'Something went wrong!', 'error');
      }
    }
  };

  const handleCartelaClick = (num) => {
    const prevValue = [...state.clickedNumbers];
    if (prevValue.includes(num)) {
      const filteredValues = prevValue.filter((item) => item !== num);
      setNewState({ clickedNumbers: filteredValues });
    } else {
      prevValue.push(num);
      setNewState({ clickedNumbers: prevValue });
    }
  };

  const displayHistoryBalls = () => {
    const last5Calls = state.historyBalls.reverse();
    if (state.historyBalls.length > 0) {
      return (<div className='!grid !grid-cols-5'>
        {last5Calls.map((ball, index) => {
          if (ball < 16) {
            return (
              <div key={index} className={'text-black bg-lime-300 rounded-full w-11/12 p-1 text-center text-nowrap'}>B{ball}</div>
            )
          } else if (16 <= ball && ball < 31) {
            return (
              <div key={index} className={'text-black bg-green-500 rounded-full w-11/12 p-1 text-center text-nowrap'}>I{ball}</div>
            )
          } else if (31 <= ball && ball < 46) {
            return (
              <div key={index} className={'text-black bg-orange-500 rounded-full w-11/12 p-1 text-center text-nowrap'}>N{ball}</div>
            )
          } else if (46 <= ball && ball < 61) {
            return (
              <div key={index} className={'text-black bg-indigo-500 rounded-full w-11/12 p-1 text-center text-nowrap'}>G{ball}</div>
            )
          } else if (61 <= ball && ball < 76) {
            return (
              <div key={index} className={'text-black bg-yellow-500 rounded-full w-11/12 p-1 text-center text-nowrap'}>O{ball}</div>
            )
          }
        })}
      </div>);
    } else {
      return (
        <div className={'p-1 text-center text-nowrap'}>History calls</div>
      );
    }
  };

  const currentBallclass = () => {
    let className = 'text-3xl font-bold p-2 rounded-full ';
    if (!state.currentBall) {
      const classNameb = className + 'text-white bg-blue-950';
      return (
        <div className={classNameb} >
          -
        </div>
      );
    }
    if (state.currentBall < 16) {
      const classNameb = className + 'text-blue-950 bg-lime-300';
      return (
        <div className={classNameb} >
          B-{state.currentBall}
        </div>
      );
    } else if (16 <= state.currentBall && state.currentBall < 31) {
      const classNameI = className + 'text-blue-500 bg-green-500';
      return (
        <div className={classNameI} >
          I-{state.currentBall}
        </div>
      );
    } else if (31 <= state.currentBall && state.currentBall < 46) {
      const classNameN = className + 'text-blue-500 bg-orange-500';
      return (
        <div className={classNameN} >
          N-{state.currentBall}
        </div>
      );
    } else if (46 <= state.currentBall && state.currentBall < 61) {
      const classNameG = className + 'text-red-950 bg-indigo-500';
      return (
        <div className={classNameG} >
          G-{state.currentBall}
        </div>
      );
    } else if (61 <= state.currentBall && state.currentBall < 76) {
      const classNameO = className + 'text-blue-950 bg-yellow-500';
      return (
        <div className={classNameO} >
          O-{state.currentBall}
        </div>
      );
    }
  };
  return (
    <div className="min-h-screen p-4 w-full mx-1 items-center">
      <div className="info-cards">
        <div className="mx-1 px-2 bg-white text-center text-blue-300 rounded">
          <div className="info-title text-nowrap">Game</div>
          <div className="text-lg">{state.game === null ? '-' : state.game.id}</div>

        </div>
        <div className="mx-1 px-2 bg-white text-center text-blue-300 rounded">
          <div className="info-title text-nowrap text-nowrap">Give out</div>
          <div className="text-lg">{state.giveout === null ? '-' : state.giveout}</div>
        </div>
        <div className="mx-1 px-2 bg-white text-center text-blue-300 rounded">
          <div className="info-title text-nowrap">Bet</div>
          <div className="text-lg">{state.game === null ? '-' : state.game.bet_amount}</div>

        </div>
        <div className="mx-1 px-2 bg-white text-center text-blue-300 rounded">
          <div className="info-title text-nowrap">Players</div>
          <div className="text-lg">{state.playerNumber === null ? '-' : state.playerNumber}</div>

        </div>
        <div className="mx-1 px-2 bg-white text-center text-blue-300 rounded">
          <div className="info-title text-nowrap">Call</div>
          <div className="text-lg">{state.totalCall}</div>
        </div>
      </div>
      <div className="max-w-md mx-auto shadow-lg rounded-lg">
        <main className="p-4 mx-auto grid !grid-cols-12">
          <div className='col-span-4'>
            <BingoBoard prevCalls={state.prevCalls} currentCall={state.currentBall} />
          </div>
          <div className='col-span-8 mx-1'>
            <div className='flex justify-around items-center  mb-1 bg-teal-400 rounded-xl px-3 py-1 font-bold text-center'>
              <div className='text-s'>Count Down</div>
              <div className='font-bold mx-2 px-2 text-center w-9 text-white py-1 bg-teal-950 rounded-full '>{state.timeLeft === 0 ? '-' : state.timeLeft}</div>

            </div>
            <div className='my-1 bg-teal-400 rounded-xl px-3 py-2 font-bold text-center'>
              <div className=''>
                {displayHistoryBalls()}
              </div>
            </div>
            <div className='justify-around items-center  mb-1 bg-teal-400 rounded-xl px-3 py-1 font-bold text-center'>
              <div className='text-s'>Current Call</div>
              <div className='font-bold p-3 text-center  text-white bg-pink-600  rounded-full w-full'>
                {currentBallclass()}
              </div>
            </div>
            {state.cartela && <Cartela card={state.cartela} onClick={handleCartelaClick} clickedNumbers={state.clickedNumbers} />}
          </div>
        </main>
        <footer className="p-4 border-t text-center">
          <button onClick={bingo} className="bg-blue-500 text-white p-2 rounded-md w-full font-bold">Bingo</button>
          <div className="grid grid-cols-2 justify-between my-2">
            <button className="bg-red-500 text-white font-bold p-2 rounded-md w-11/12">Leave</button>
            <button className="bg-gray-500 text-white font-bold p-2 rounded-md w-11/12">Reload</button>
          </div>
        </footer>
      </div>
    </div>
  )
}
