import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import GamePage from './pages/GamePage';
import CardGrid from './pages/CardGrid';

const router = createBrowserRouter([
  {path:"/", element: <App/> },
  {path:"/select", element: <>
  <CardGrid/>
  </> },
  {path:"/game", element: <>
  <GamePage/>
  </> },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <RouterProvider router={router} />
  </React.StrictMode>
);
